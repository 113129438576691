* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.demo {
  display: flex;
  width: 100%;
  overflow: hidden;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.tableWrapper,
.pageWrapper {
  height: 100%;
  /* padding: 20px; */
  overflow: auto;
  flex: 1;
  margin: 0;
}

.tableWrapper{
  padding: 16px 32px;
}

.pageWrapper {
  padding: 32px;
  padding-top: 16px;
}
.button-section {
  display: flex;
  align-items: center;
  padding: 0em 0 2em 0;
  width: 100%;
  justify-content: space-between;
}
.source-provider {
  width: fit-content;
}
.source-provider,
.autocompelete-section,
.button-array,
.upload-section {
  display: inline-flex;
  align-items: center;
}
.autocompelete-section {
  justify-content: left;
}
.button-array {
  justify-content: center;
  width: max-content;
}
.button-section button {
  margin: 0 1em 0 1em;
  margin-right: 0;
  /* min-width: 120px; */
}
.button-array {
  
}
.upload-section {
  width: fit-content;
}
.autocompelete-section > span {
  padding-right: 20px;
}
.upload-section button {
  min-width: 120px;
}
.rgt-columns-manager-button {
  min-width: 24px;
}

#red_color {
  color: red;
}

/* Rectangle 353 */
.file {
  margin-top: 40px;
}

.acc_con {
  width: 500px;
  margin-bottom: 16px;

  /* Heavy/Subtitle */

  /* identical to box height, or 117% */

  color: #004138;
}

.MuiOutlinedInput-inputMarginDense{
  padding-top: 6.5px !important;
  padding-bottom: 10.5px !important;
  padding-left: 12px !important;
}
.MuiInputLabel-outlined.MuiInputLabel-marginDense{
  padding: 0;
}
.MuiAutocomplete-inputRoot{
  padding: 0 !important; 
}

.rgt-cell, .rgt-cell a {
  color: #595959;
}
