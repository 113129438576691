:root {
  width: 100%;
}

/* general */

.name{
  color: pink;
}
.input-row{
  
  width: 100%;
  padding:0 0 0 32px;
  display: inline-flex;
}
/* .input-row >*{
  margin-right: 150px;
} */
.flex-container>.button-section{
  width: 78px;
  /* margin-right: 24px; */
}