.pagination-button{
    margin: 0.2rem!important;
    padding: 0.1rem!important;
    color: #21DA8C;
    background: white;
    cursor: pointer;
    border: 0px;
}

.active{
    text-decoration: underline;
}