* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'Nunito Sans', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.demo {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
  font-family: 'Nunito Sans', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.demo ::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.demo ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

.demo ::-webkit-scrollbar-thumb {
  background-color: #dddd;
  border: 5px solid #d8d8d8;
}


@media (min-width: 1025px) {
  .tableWrapper {
    padding: 32px;
    padding-top: 16px;
  }
  .settingsDrawer {
    position: unset !important;
    transform: translate3d(0px, 0px, 0px) !important;
  }
  .settingsDrawerButton {
    display: none !important;
  }
}

.source-provider,
.button-section {
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
  justify-content: space-between;
}
.autocompelete-section,
.button-array,
.upload-section {
  display: inline-flex;
  align-items: center;
}
.button-array {
  justify-content: center;
  width: fit-content;
}
.button-section button {
  margin: 0 1em 0 1em;
  margin-right: 0;
  /* min-width: 120px; */
}
.upload-section {
  width: fit-content;
}
.autocompelete-section > span {
  padding-right: 20px;
}
.upload-section button {
  min-width: 120px;
}
.rgt-columns-manager-button {
  min-width: 24px;
}

#red_color {
  color: red;
}

/* Rectangle 353 */

.contact_text {
  /* Account Hub */

  /* Caption */

  /* position: absolute;
left: 0%;
right: 86.17%;
top: 10%;
bottom: 10%; */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.44px;

  color: #000000;

  height: 28px;
  left: 90px;
  top: 143px;
  margin-left: -10px;
}
.account-details {
  height: calc(100%-64px);
  padding-top: 10px;
}
.button-array {
  margin: 0;
}

.MuiOutlinedInput-inputMarginDense {
  padding-top: 6.5px !important;
  padding-bottom: 10.5px !important;
  padding-left: 12px !important;
}
.MuiInputLabel-outlined.MuiInputLabel-marginDense {
  top: 0 !important;
}
.input-row {
  width: 100%;
  padding: 0 0 0 32px;
  display: inline-flex;
}

.input-section {
  padding-top: 26px;
  /* box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12); */
  /* padding-bottom: -16px; */
  /* border-radius: 4px; */
  border-bottom: 1px solid #D3D3D3;
}

.MuiTableCell-body {
  color:#595959 !important
}

.MuiBox-root {
  overflow: auto;
  max-height: 40vh;
}

.MuiBox-root::-webkit-scrollbar-track {
  background-color: #f5f5f5 !important;
}

.MuiBox-root::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  background-color: #f5f5f5;
}

.MuiBox-root::-webkit-scrollbar-thumb {
  background-color: #dddd !important;
  border: 5px solid #d8d8d8 !important;
  border-radius: 16px;
}